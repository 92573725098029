@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/font-awesome/scss/font-awesome";


/* ===============================
	base
 =============================== */

body {
	margin-left: auto;
	margin-right: auto;
	max-width: 1800px;
	font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", HelveticaNeue, "Segoe UI", "Hiragino Kaku Gothic Pro", Meiryo, "ヒラギノ角ゴ Pro W3", "メイリオ", "MS PGothic", "MS UI Gothic", sans-serif;
	line-height: 1.75;
	color: #002108;
  -webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (max-width: 767px) {
	  font-size: $font-size-sm;
  }
}

@keyframes fadeIN {
  0% { 
		animation-timing-function: ease-in-out;
		opacity: 0;
	}
  100% { 
	  opacity: 1;
  }
}
@keyframes fadeOUT {
  0% { 
		animation-timing-function: ease-in-out;
		opacity: 1;
	}
  100% { 
	  opacity: 0;
  }
}

.fade-in {
	animation: fadeIN .8s ease-in-out 0s 1 normal forwards;
}
.fade-out {
	animation: fadeOUT .4s ease-in-out 0s 1 normal forwards;
}

* { position: relative; }

a, button {
	color: #0f6f47;
	transition: .3s;
	&:hover {
		color: #ca3842;
		@include media-breakpoint-up(lg) {
			cursor: pointer;
		}
	}
	&:focus { 
		outline: none !important;
		box-shadow: none !important;
	}
	&:active { text-decoration: none !important; }
}

@include media-breakpoint-up(md) {
  a[href^="tel:"]{
    pointer-events: none;
  }
}



/* ===============================
	color
 =============================== */

.text-primary {
	color: #0f6f47 !important;
}

.text-dark {
	color: #002108 !important;
}

.text-yellowgreen {
	color: #cbdf26 !important;
}

.link-hover-primary {
	color: #002108;
	&:hover, &:active {
		color: #0f6f47 !important;
	}
}
.link-hover-danger {
	color: #002108;
	&:hover, &:active {
		color: #ca3842 !important;
	}
}

.border-light {
	border: 1px solid #e5e8e6 !important;
}

.border-top-light {
	border-top: 1px solid #e5e8e6 !important;
}

.border-bottom-light {
	border-bottom: 1px solid #e5e8e6 !important;
}

.bg-primary {
	background-color: #0f6f47 !important;
}

.bg-light-yellow {
	background-color: #f7f9e3;
}

.bg-green {
	background-color: #e0efe2;
}

.bg-light-green {
	background-color: #f6fcf9;
}


/* ===============================
	typography
 =============================== */

@keyframes fade-logo {
  0% { 
		animation-timing-function: ease-in-out;
		transform:  translate(0, 0); 
		opacity: 0;
	}
  100% { 
	  opacity: 1;
	  transform:  translate(10px, 0px); 
  }
}

.heading-home {
	margin: 150px 0 0;
	width: 194px;
	height: 71px;
	opacity: 0;
	animation: fade-logo 2s ease-in-out 2s 1 normal forwards;
	@include media-breakpoint-up(md) {
		margin: 100px 0 0;
		width: 388px;
		height: 142px;
	}
	@include media-breakpoint-up(lg) {
		margin: 200px 0 0;
	}
}

.heading-recruit_copy {
	margin: 150px 0 0;
	width: 170.5px;
	height: 71px;
	@include media-breakpoint-up(md) {
		margin: 50px 0 0;
	}
	@include media-breakpoint-up(lg) {
		margin: 200px 0 0;
		width: 341px;
		height: 142px;
	}
}

.page-heading {
	margin-bottom: 0;
	font-weight: 700;
	.heading-before {
		display: block;
		margin-bottom: .25rem;
		height: 24px;
		color: #0f6f47;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.3;
		background: url(../imgs/icon-leaf.png) center center no-repeat;
		background-size: contain;
	}
}

.section-heading {
	margin-bottom: 30px;
	font-weight: 700;
	.heading-before {
		display: block;
		margin-bottom: .5rem;
		font-size: 1rem;
		font-weight: 400;
	}
	.heading-before-circle {
		display: inline-block;
		top: -5px;
		left: -10px;
		width: 48px;
		height: 48px;
		color: #fff;
		font-size: .75rem;
		font-weight: 700;
		line-height: 48px;
		text-align: center;
		border-radius: 30px;
	}
	@include media-breakpoint-up(md) {
		margin-bottom: 40px;
		.heading-before-circle {
			width: 60px;
			height: 60px;
			color: #fff;
			font-size: .875rem;
			line-height: 60px;
		}
	}
}

.heading {
	font-weight: 700;
}

.heading-sideline {
	display: inline-block;
	font-weight: 700;
	padding: 0 40px;
	&:before, &:after {
	  display: inline-block;
	  position: absolute;
	  top: 50%;
	  content: "";
	  width: 30px;
	  height: 1px;
	  background-color: #0f6f47;
	}
	&:before {
	  left:0;
	}
	&:after {
	  right: 0;
	}
	@include media-breakpoint-up(sm) {
		padding: 0 110px;
		&:before, &:after {
			width: 100px;
		}
	}
}

h2 {
	font-size: 1.375rem;
	@include media-breakpoint-up(sm) {
		font-size: 1.75rem;
	}
}

h3 {
	font-size: 20px;
	@include media-breakpoint-up(lg) {
		font-size: 24px;
	}
}

h5 {
	font-size: 16px;
	@include media-breakpoint-up(md) {
		font-size: 20px;
	}
}
 
 p {
	 margin-bottom: 0;
	 line-height: 1.8;
	 +p {
		 margin-top: 1rem;
	 }
	 +.btn {
		 margin-top: 50px;
	 }
 }

.text-xl { font-size: 30px !important; }
.text-lg { font-size: 20px !important; }
.text-sm { font-size: 14px !important; }
.text-xs { font-size: 12px !important; }


.line-height-narrow {
	line-height: 1.3 !important;
}
.line-height-base {
	line-height: 1.5 !important;
}
.line-height-wide {
	line-height: 1.75 !important;
}

.letter-spacing-wide {
	display: inline-block;
	text-indent: .375em !important;
	letter-spacing: .375em !important;
}
 


/* ===============================
	icons
 =============================== */

i:before, i:after {
	position: relative;
	display: inline-block;
	font-family: 'FontAwesome';
	font-style: normal;
}
 


/* ===============================
	images
 =============================== */

.site-logo {
	width: 136px;
	height: 60px;
}

.logo-olc {
	width: 150px;
	height: 20px;
}

figure {
	&.img > img {
		width: 100%;
	}
}

.bg-img,
.bg-imgs__home {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: -1;
	figure {
		position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: none;
    &.img-about {
	    background-image: url(../imgs/bg-about_sm.jpg);
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/bg-about.jpg);
	    }
    }
    &.img-works {
	    background-image: url(../imgs/bg-works_sm.jpg);
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/bg-works.jpg);
	    }
    }
    &.img-company {
	    background-image: url(../imgs/bg-company_sm.jpg);
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/bg-company.jpg);
	    }
    }
    &.img-recruit {
	    background-image: url(../imgs/bg-recruit_sm.jpg);
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/bg-recruit.jpg);
	    }
    }
    &.img-feature {
	    background-image: url(../imgs/bg-feature_sm.jpg);
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/bg-feature.jpg);
	    }
    }
    &.img-message {
	    background-image: url(../imgs/bg-message_sm.jpg);
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/bg-message.jpg);
	    }
    }
    &.img-interview-ks {
	    background-image: url(../imgs/bg-interview-ks_sm.jpg);
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/bg-interview-ks.jpg);
	    }
    }
    &.img-interview-ot {
	    background-image: url(../imgs/bg-interview-ot_sm.jpg);
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/bg-interview-ot.jpg);
	    }
    }
    &.img-entry {
	    background-image: url(../imgs/bg-entry_sm.jpg);
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/bg-entry.jpg);
	    }
    }
    &.img-contact {
	    background-image: url(../imgs/bg-contact_sm.jpg);
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/bg-contact.jpg);
	    }
    }
	}
}

.bg-imgs__home {
	figure {
		position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: none;
    opacity: 0;
    animation: anime 40s linear 0s infinite ;
    &.img-home__01 {
	    background-image: url(../imgs/img-home04_sm.jpg);
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/img-home04.jpg);
	    }
    }
    &.img-home__02 {
	    background-image: url(../imgs/img-home01_sm.jpg);
	    -webkit-animation-delay: 10s;
			animation-delay: 10s;
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/img-home01.jpg);
	    }
    }
    &.img-home__03 {
	    background-image: url(../imgs/img-home03_sm.jpg);
	    -webkit-animation-delay: 20s;
			animation-delay: 20s;
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/img-home03.jpg);
	    }
    }
    &.img-home__04 {
	    background-image: url(../imgs/img-home02_sm.jpg);
	    -webkit-animation-delay: 30s;
			animation-delay: 30s;
	    @include media-breakpoint-up(lg) {
		    background-image: url(../imgs/img-home02.jpg);
	    }
    }
	}
}
@keyframes anime { 
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
  }
  10% {
    transform: scale(1.1);
    opacity: 1;
  }
  30% {
    transform: scale(1.2);
    animation-timing-function: ease-out;
    opacity: 1;
  }
  50% {
    transform: scale(1.3);
    opacity: 0;
  }
  100% { opacity: 0 }
}

.bg {
	margin: 50px 30px 0;
	height: 200px;
	&-left, &-right {
		z-index: 2;
	}
	@include media-breakpoint-up(lg) {
		position: absolute;
		top: 0;
		margin: 0;
		height: 100%;
		&-left {
			left: 0;
			width: 50%;
		}
		&-right {
			right: 0;
			width: 50%;
		}
		&-fluid {
			right: 0;
			width: 100%;
			z-index: -1;
		}
	}
	&.img-section-home_about {
		background: url(../imgs/img-about_sm.jpg) center center no-repeat;
		background-size: cover;
	}
	&.img-section-home_recruit {
		background: url(../imgs/img-recruit_sm.jpg) center center no-repeat;
		background-size: cover;
	}
	&.img-section-recruit_feature {
		background: url(../imgs/img-feature_sm.jpg) center center no-repeat;
		background-size: cover;
	}
	&.img-section-recruit_interview {
		background: url(../imgs/img-interview_sm.jpg) center center no-repeat;
		background-size: cover;
	}
	&.img-section-interview_profile_ks {
		background: url(../imgs/img-interview-ks_sm.jpg) center center no-repeat;
		background-size: cover;
	}
	&.img-section-interview_ks {
		background: url(../imgs/img-interview-ks04_sm.jpg) center center no-repeat;
		background-size: cover;
	}
	&.img-section-interview_profile_ot {
		background: url(../imgs/img-interview-ot_sm.jpg) center center no-repeat;
		background-size: cover;
	}
	&.img-section-interview_ot {
		background: url(../imgs/img-interview-ot04_sm.jpg) center center no-repeat;
		background-size: cover;
	}
	&.img-section-footer_company {
		background: url(../imgs/img-company_sm.jpg) center center no-repeat;
		background-size: cover;
	}
	@include media-breakpoint-up(lg) {
		&.img-section-home_about {
			background-image: url(../imgs/img-about.jpg);
			background-size: cover;
		}
		&.img-section-home_recruit {
			background-image: url(../imgs/img-recruit.jpg);
			background-size: cover;
		}
		&.img-section-recruit_feature {
			background-image: url(../imgs/img-feature.jpg);
			background-size: cover;
		}
		&.img-section-recruit_interview {
			background-image: url(../imgs/img-interview.jpg);
			background-size: cover;
		}
		&.img-section-interview_profile_ks {
			background-image: url(../imgs/img-interview-ks.jpg);
			background-size: cover;
		}
		&.img-section-interview_ks {
			background-image: url(../imgs/img-interview-ks04.jpg);
			background-size: cover;
		}
		&.img-section-interview_profile_ot {
			background-image: url(../imgs/img-interview-ot.jpg);
			background-size: cover;
		}
		&.img-section-interview_ot {
			background-image: url(../imgs/img-interview-ot04.jpg);
			background-size: cover;
		}
		&.img-section-footer_company {
			background-image: url(../imgs/img-company.jpg);
			background-size: cover;
		}
	}
}

.link-requirement {
	> img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto;
		z-index: -1;
	}
	@include media-breakpoint-up(lg) {
		> img {
		  top: 50%;
		  left: 50%;
		  -webkit-transform: translate(-50%, -50%);
		  -ms-transform: translate(-50%, -50%);
		  transform: translate(-50%, -50%);
		  width: auto;
		  height: 100%;
		  transition: .3s;
		  z-index: -1;
		}
		&:hover {
			> img {
			  height: 110%;
			}
		}
	}
}



/* ===============================
	list
 =============================== */

ul, ol {
	padding-left: 20px;
}

dl {
	&.dl-horizontal {
		border-top: 1px solid #e5e8e6;
		dt {
			padding-top: 1rem;
		}
		dd {
			margin: 0;
			padding: .5rem 0 1rem;
			border-bottom: 1px solid #e5e8e6;
		}
		@include media-breakpoint-up(md) {
			dt {
				position: absolute;
				padding-top: 1rem;
			}
			dd {
				padding: 1rem 0;
				padding-left: 10em;
			}
		}
		@include media-breakpoint-up(lg) {
			dt {
				padding-top: 1.5rem;
			}
			dd {
				padding: 1.5rem 0;
				padding-left: 12em;
			}
		}
	}
}


/* ===============================
	button
 =============================== */

.btn {
	line-height: 1.3;
	border-radius: 0;
	
	@include media-breakpoint-up(lg) {
			cursor: pointer;
		}
	
	&.btn-danger {
		background-color: #ca3842;
	}
	&.btn-dark {
		padding: 1.5rem;
		min-width: 300px;
		color: #fff;
		font-weight: 700;
		background-color: #002108;
		border: 1px solid #002108;
	}
	&.btn-outline {
		padding: .75rem 1rem;
		min-width: 150px;
		font-size: .875rem;
		border: 1px solid #002108;
		@include media-breakpoint-up(md) {
			padding: 1rem;
			min-width: 240px;
			font-size: 1rem;
		}
	}
	&.btn-lg.btn-outline {
		padding: 1.5rem;
		min-width: 300px;
		font-size: 1.25rem;
		font-weight: 700;
	}
	&.btn-dark, &.btn-outline {
		&:hover, &:active {
			@include media-breakpoint-up(lg) {
				color: #fff;
				background: #0f6f47;
				border-color: #0f6f47;
			}
		}
	}
	&.btn-more {
		margin-top: 30px;
		&:after {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: .5rem;
			margin-top: auto;
			margin-bottom: auto;
			height: 1.25rem;
			content: "\f178";
			font-family: 'FontAwesome';
		}
		&.btn-dark {
			&:after {
				height: 1.75rem;
			}
		}
		@include media-breakpoint-up(md) {
			margin-top: 40px;
			&:after {
				right: 1.25rem;
			}
		}
		@include media-breakpoint-up(lg) {
			margin-top: 50px;
		}
	}
	&.btn-prev {
		&:after {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: .5rem;
			margin-top: auto;
			margin-bottom: auto;
			height: 1.25rem;
			content: "\f177";
			font-family: 'FontAwesome';
		}
		@include media-breakpoint-up(md) {
			&:after {
				left: 1.25rem;
			}
		}
	}
	&.btn-fixed-footer {
		position: fixed;
		left: 0;
		bottom: 0;
		padding: .5rem;
		width: 100%;
		z-index: 1000;
		&:after {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 1.25rem;
			margin-top: auto;
			margin-bottom: auto;
			height: 1.25rem;
			content: "\f054";
			font-family: 'FontAwesome';
		}
	}
}



/* ===============================
	nav
 =============================== */

.navbar {
	padding: 0;
	.navbar-brand {
		margin: 20px 0 0;
		padding: 0;
	}
	.btn-group {
		position: absolute;
		top: 0;
		right: 80px;
		height: 80px;
		.dropdown-toggle {
			&:after {
		    position: absolute;
		    top: 0;
		    bottom: 0;
		    right: 15px;
				display: block;
				margin-top: auto;
				margin-bottom: auto;
				height: .6em;
		    margin-left: 0;
			}
		}
	}
}

#recruit .navbar .btn-fixed-footer,
#requirement .navbar .btn-fixed-footer {
	display: none !important;
}

.navbar-toggler {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0;
	width: 80px;
	height: 80px;
	color: #fff !important;
	background-color: #000;
	border: none;
	border-radius: 0;
	z-index: 10000;
	i {
		display: block;
		margin-top: -2px;
		font-size: 1.875rem;
		&:before {
			content: "\f00d";
		}
	}
	&.collapsed {
		i {
			&:before {
				content: "\f0c9";
			}
		}
	}
	@include media-breakpoint-up(lg) {
		width: 80px;
		height: 80px;
		i {
			margin-top: -4px;
			font-size: 1.75rem;
		}
	}
}

.navbar-collapse {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,.8);
	z-index: 9999;

	.navbar-nav {
		padding: 1rem 2rem 2rem;
		width: 100%;
		text-align: center;
		.nav-link {
			padding-top: 1.25rem;
			padding-bottom: 1.25rem;
			color: #fff;
			font-size: 1.125rem;
			font-weight: 700;
			@include media-breakpoint-up(lg) {
				padding-top: 2rem;
				padding-bottom: 2rem;
				color: #fff;
				font-size: 1.5rem;
			}
		}
	}
}

.collapsing {
  position: absolute;
  height: 100vh;
  transition: none;
}


/* dropdown ----------------------------- */

.dropdown-menu {
  float: left;
  min-width: 10rem;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  background-color: #ca3842;
  border: none;
  border-radius: 0;
}

.dropdown-item {
  padding: 1rem 1.6rem;
  color: #fff;
  &:focus, &:hover {
    color: #fff;
    background-color: rgba(255,255,255,.1);
	}
}

.dropdown-divider {
	margin: 0;
	opacity: .25;
}


/* ===============================
	form
 =============================== */
 
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

input::-webkit-input-placeholder {
  color: #ddd !important;
}
input:-ms-input-placeholder {
	color: #ddd !important;
}
input::placeholder {
	color: #ddd !important;
}


.form-control {
	padding: .75rem;
  height: calc(1.5em + 1.5rem + 2px);
  @include media-breakpoint-up(md) {
		padding: 1rem;
	  height: calc(1.5em + 2rem + 2px);
  }
}

.col-form-label {
  padding-top: calc(1rem + 1px);
  padding-bottom: calc(0rem + 1px);
}


/* Mailform ----------------------------- */

ul.form-list-conform {
	> li:nth-child(8) {
		display: none;
	}
}




/* ===============================
	components
 =============================== */
 
header {
	&.site-header {
		height: 240px;
	}
	&.site-header__home,
	&.site-header__recruit {
		height: 480px;
	}
	@include media-breakpoint-up(md) {
		&.site-header {
			height: 420px;
		}
	}
	@include media-breakpoint-up(lg) {
		&.site-header__home,
		&.site-header__recruit {
			height: 720px;
		}
	}
}

.wrapper-page-heading {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	padding-top: 40px;
	margin: 0 auto;
	text-align: center;
	background-color: #fff;
	@include media-breakpoint-down(sm) {
		padding-top: 30px;
		margin-left: 5%;
		margin-right: 5%;
		width: 90%;
	}
}

section {
	&.section {
		margin-top: 50px;
		padding: 0 0 50px;
		.section-body {
			padding: 0 30px;
			z-index: 3;
		}
		&.layer-under {
			margin-top: -150px;
			padding-top: 150px;
			.section-body {
			}
		}
		@include media-breakpoint-up(lg) {
			margin-top: 100px;
			padding: 50px 0;
			.section-body {
				padding: 50px;
			}
			&.layer-under {
				margin-top: -100px;
				.section-body {
					padding-top: 50px;
				}
			}
		}
		&.section-interview_profile {
			.section-body {
				padding: 30px;
			}
			@include media-breakpoint-up(lg) {
				.section-body {
					padding: 50px;
				}
			}
		}
		&.section-recruit_interview {
			.section-body + .section-body {
				padding-top: 30px;
			}
			@include media-breakpoint-up(lg) {
				.section-body + .section-body {
					padding-top: 50px;
				}
			}
		}
	}
}



/* ===============================
	structure
 =============================== */

/* Home ----------------------------- */

section.section-home_works {
	.section-body {
		.content {
			> div {
				flex-basis: 50%;
				.icon-home_works {
					width: 100px;
					height: 92px;
				}
			}
			@include media-breakpoint-up(md) {
				> div {
					flex-basis: 33%;
				}
			}
			@include media-breakpoint-up(lg) {
				> div {
					flex-basis: 20%;
				}
			}
			@include media-breakpoint-up(xl) {
				> div {
					flex-basis: 200px;
				}
			}
		}
	}
}

section.section-home_requirement {
	.section-body {
		.content {
			.link-requirement {
				flex-basis: 50%;
				height: 240px;
				border: 1px solid #e0efe2;
				.link-requirement__body {
					width: 100%;
					min-height: 105px;
				}
			}
			@include media-breakpoint-up(md) {
				.link-requirement {
					flex-basis: 25%;
					.link-requirement__body {
						min-height: 110px;
					}
				}
			}
			@include media-breakpoint-up(lg) {
				.link-requirement {
					height: 260px;
					.link-requirement__body {
						width: 200px;
					}
				}
			}
			@include media-breakpoint-up(xl) {
				.link-requirement {
					flex-basis: 20%;
				}
			}
		}
	}
}

/* about ----------------------------- */

section.section-about_values,
section.section-requirement_values {
	.section-body {
		.content {
			> div {
				flex-basis: 98%;
			}
			@include media-breakpoint-up(md) {
				> div {
					flex-basis: 48%;
				}
			}
		}
	}
}

.section-company_associated {
	.section-body {
		.content {
		}
	}
	@include media-breakpoint-up(md) {
		.content {
			> div {
				flex-basis: 48%;
			}
		}
	}
}

/* Works ----------------------------- */

section.section-works_type {
	.body-inner {
		.icon-works_type {
			width: 150px;
			height: 138px;
		}
	}
}

/* Recruit ----------------------------- */

section.section-recruit_interview {
	.container {
		margin-top: 250px;
		.section-body {
			padding-top: 50px;
		}
	}
	&.ot {
		.container {
			margin-top: 200px;
			.section-body {
				padding-bottom: 50px;
			}
		}
	}
	.bg {
		position: absolute;
		top: -200px;
		margin-top: 0;
		width: calc(100% - 60px);
	}
	@include media-breakpoint-up(md) {
		.bg {
			.img-section-interview_ks {
			}
			.img-section-interview_ot {
				background-position-y: -120px;
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.container {
			margin-top: 0;
		}
		&.ot {
			.container {
				margin-top: 0;
			}
		}
		.bg {
			top: 0;
			width: 50%;
			&.img-section-interview_ks {
				background-position-y: 0;
			}
		}
	}
}


/* footer ----------------------------- */

section.section-footer_company {
	.section-body {
		margin-top: 30px;
		margin-bottom: 30px;
	}
	.bg {
		position: absolute;
		top: 0;
		margin: 0;
		height: 100%;
		&-fluid {
			right: 0;
			width: 100%;
			z-index: -1;
		}
	}
	@include media-breakpoint-down(md) {
		padding-bottom: 0;
		.section-body {
			padding: 30px;
		}
	}
}

section.section-footer_contact {
	.row {
		&:after {
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			margin-top: auto;
			margin-bottom: auto;
			width: 100%;
			height: 1px;
			content: "";
			background-color: #ccd3ce;
			z-index: -1;
		}
	}
}

.site-footer {
	margin-bottom: 100px;
	.copyright {
		font-size: 11px;
	}
	@include media-breakpoint-up(md) {
		margin-bottom: 50px;
	}
}

#requirement .site-footer {
	margin-bottom: 20px;
	@include media-breakpoint-up(md) {
		margin-bottom: 50px;
	}
}


/* ===============================
	utility
 =============================== */


 
